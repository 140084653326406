<template>
    <div>
        <Header title="Regras" addText="Regra" :hasAddBtn="false" />
        <b-table sticky-header="65vh" no-border-collapse hover :items="$store.state.rules" :fields="fields"></b-table>
    </div>
</template>

<script>

import Header from '@/components/Dashboard/Header'

export default {
  components: {
    Header
  },
  data: () => ({
    fields: [
      { key: 'key', label: 'Chave', sortable: true },
      { key: 'description', label: 'Descrição', sortable: true }
    ]
  })
}
</script>
